<template>
    <div>
        <div class="table_content">
            <!-- 表格内容 -->
            <commonTitle></commonTitle>
            <div class="search">
                <el-form ref="form" :model="form" label-width="70px" :inline="true">
                    <el-form-item label="户室号:">
                        <el-input v-model="form.name" placeholder="请输入户室编号" size="mini"></el-input>
                    </el-form-item>
                    <button @click.prevent="common()" style="cursor: pointer">搜索</button>
                    <button @click.prevent="add" style="cursor: pointer" v-show="isShow">新建</button>
                </el-form>
            </div>
            <el-card>
                <el-table
                        :data="tableData"
                        v-loading="loading"
                        style="width:100%"
                        element-loading-text="拼命加载中"
                        
                        :default-sort="{prop: 'settingTime', order: 'descending'}"
                        :stripe="true"
                >
                    <el-table-column
                            fixed
                            prop="houseNum"
                            label="户室编号"
                            width="120px">
                    </el-table-column>

                    <el-table-column
                            prop="villageName"
                            label="小区名称"
                            show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                            prop="towerNum"
                            label="楼幢号"
                            width="120px">
                    </el-table-column>
                    <el-table-column
                            prop="unitName"
                            label="单元名称"
                            show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                            prop="coveredarea"
                            label="建筑面积"
                            show-overflow-tooltip
                    >
                    </el-table-column>

                    <el-table-column
                            prop="housPurchase"
                            label="购房款"

                    >
                    </el-table-column>
                    <el-table-column
                            prop="ownerName"
                            label="业主"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="houseType"
                            label="户室类型"
                            :formatter="formatterHouseType"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="state"
                            label="房屋状态"
                            :formatter="formatterHouseState"
                    >
                    </el-table-column>
                    <el-table-column
                            fixed="right"
                            label="操作"
                            width="120">
                        <template slot-scope="scope">
                            <el-button @click="upUnit(scope.row)" type="text" size="small"
                                       :disabled="scope.row.actResult!=4">修改
                            </el-button>
                            <el-button
                                    @click.native.prevent="deleteRow(scope.$index, scope.row,tableData)"
                                    type="text"
                                    size="small" :disabled="scope.row.actResult!=4">
                                删除
                            </el-button>
                            <el-button
                                    type="text"
                                    size="mini"
									:disabled="scope.row.state == 2?true:false"
                                    @click.prevent="bindHouseOwner(scope.row)"
                            >
                                绑定
                            </el-button>
                            <el-button
                                    size="mini"
                                    type="text"
                                    :disabled="false"
                                    @click="uploadfile(scope.row)">上传
                            </el-button>
                            <el-button
                                    size="mini"
                                    type="text"
                                    :disabled="false"
                                    @click="downloadfile(scope.row)">下载
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-row>
                    <el-pagination
                            background
                            style="margin-top: 50px"
                            layout="prev, pager, next"
                            :page-size="15"
                            @current-change="handleCurrentChange"
                            :total="totalNum">
                    </el-pagination>
                    <el-button @click="back" class="btn" size="small">上一步</el-button>
                </el-row>
            </el-card>
        </div>
        <!--上传弹框-->
        <el-dialog
                title="设计图上传"
                :visible.sync="dialogVisible"
                width="30%"
                style="text-align: center">
            <el-upload
                    accept="image/png,image/jpg"
                    class="upload-demo"
                    drag
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :before-remove="beforeRemove"
                    :file-list="fileList"
                    :on-success="handleAvatarSuccess"
                    :action="designUrl"
                    :before-upload="designUpload"
                    :limit="1"
                    :auto-upload="true"
                    :on-exceed="handleExceed"
                    multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传png/jpg 格式文件
                </div>
            </el-upload>
            <span slot="footer" class="dialog-footer">
               <el-button @click="dialogVisible = false">关 闭</el-button>
             </span>
        </el-dialog>
        <el-dialog
                title="绑定业主"
                :visible.sync="dialogVisibleBindOwner"
                style="vertical-align: middle"
                width="30%"
                :before-close="closeDiv"
        >
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                     size="small">
                <el-form-item label="姓名" prop="ownerName">
                    <el-input v-model="ruleForm.ownerName" size="small" style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="idCard">
                    <el-input v-model="ruleForm.idCard" size="small" style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="ruleForm.phone" size="small" style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">立即绑定</el-button>
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
    import commonTitle from "./commonTitle";

    export default {
        name: "deveHouse",
        components: {
            commonTitle
        },
        data() {
            let validID = (rule, value, callback) => {
                if (value) {
                    let reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
                    if (!reg.test(value)) {
                        callback(new Error('身份证号码不正确'));
                    } else {
                        callback();
                    }
                }
                callback();

            }
            //前台校验手机号码
            let validPhone = (rule, value, callback) => {
                if (value) {
                    let reg = /^1[3456789]\d{9}$/
                    if (!reg.test(value)) {
                        callback(new Error('手机号码格式不正确'));
                    } else {
                        callback();
                    }
                }
                callback();

            }

            return {
                tableData: [],//表格数据集
                totalNum: 15,
                form: {
                    name: "",
                    region: '',
                },
                loading: false,
                url: this.$Config.base_server,
                isShow: '',
                dialogVisibleBindOwner: false,
                dialogVisible: false,
                designFileId:'',
                fileList: [],
                designUrl: '',
                rowNumber: {},
                ruleForm: {
                    ownerName: '',
                    idCard: '',
                    phone: '',
                    houseId: ''
                }, rules: {
                    ownerName: [
                        {required: true, message: '请输入业主姓名', trigger: 'change'}
                    ],
                    idCard: [
                        {required: true, trigger: 'change', message: '请输入身份证号码'},
                        {validator: validID, trigger: 'change'}
                    ],
                    phone: [
                        {required: true, trigger: 'change', message: '请输入手机号码'},
                        {validator: validPhone, trigger: 'change'}
                    ],
                },
                data: {
                    access_token: localStorage.getItem("token"),
                    limit: 15,
                    searchKey: 'unitId',
                    searchValue: JSON.parse(localStorage.getItem("unitData")).unitId
                }
            };
        }, mounted() {
            this.common();
            let actResult = JSON.parse(localStorage.getItem("villageData")).actResult;
            if (actResult == 4) {
                //小区未提交状态
                this.isShow = true;
            } else {
                //小区其他状态
                this.isShow = false;
            }
        }, methods: {
            common(currentPage = 0) {
                //初始化单元数据
                const that = this;
                that.loading = true;
                that.data.page = currentPage;
                that.data.houseNum = that.form.name;
                $.ajax({
                    url: that.url + "/api-public/opehouse/list",
                    data: that.data,
                    contentType: "application/json",
                    type: "get",
                    success: function (res) {
                        if (res.code == 0) {
                            that.loading = false;
                            that.totalNum = res.count;
                            that.tableData = res.data;
                        }
                    },
                    error: function (res) {
                        that.loading = false;
                    }
                });
            },
            designUpload(){
                return new Promise((resolve, reject) => {
                    this.$nextTick(() => {
                        this.designUrl = this.url + "/api-file/files/anon?access_token=" + localStorage.getItem("token"),
                            resolve()
                    })
                })
            },
            handleAvatarSuccess(res, file) {
                const _this = this;
                _this.imageUrl = URL.createObjectURL(file.raw);
                const data = {
                    designFileId:res.id,
                    houseId:_this.rowNumber.houseId,
                    houseNum:_this.rowNumber.houseNum,
                    unitId:_this.rowNumber.unitId,
                    coveredarea:_this.rowNumber.coveredarea,
                    villageId:_this.rowNumber.villageId,
                }
                $.ajax({
                    url: _this.url + "/api-public/opehouse/update?access_token=" + localStorage.getItem("token"),
                    type: 'post',
                    data: JSON.stringify(data),
                    contentType: 'application/json',
                    success: function (result) {
                        if (result.resp_code == 0) {
                            _this.loading = false;
                            //修改成功
                            _this.loading = false;
                            _this.$message({
                                message: '户室设计图已更新!',
                                dangerouslyUseHTMLString: true,
                                type: 'success',
                                duration: 1000,
                            });
                        } else {
                            _this.loading = false;
                            //修改失败
                            _this.$message({
                                message: result.resp_msg,
                                dangerouslyUseHTMLString: true,
                                type: 'warning'
                            });
                        }
                    }
                });
                console.log(this.designFileId)
            },
            handleRemove(file, fileList) {
                const _this = this;
                if (_this.designFileId==file.response.id){
                    _this.designFileId=null;
                    const data = {
                        designFileId:null,
                        houseId:_this.rowNumber.houseId,
                        houseNum:_this.rowNumber.houseNum,
                        unitId:_this.rowNumber.unitId,
                        coveredarea:_this.rowNumber.coveredarea,
                        villageId:_this.rowNumber.villageId,
                    }
                    $.ajax({
                        url: _this.url + "/api-public/opehouse/update?access_token=" + localStorage.getItem("token"),
                        type: 'post',
                        data: JSON.stringify(data),
                        contentType: 'application/json',
                        success: function (result) {
                            if (result.resp_code == 0) {
                                _this.loading = false;
                                //修改成功
                                _this.$message({
                                    message: result.resp_msg,
                                    dangerouslyUseHTMLString: true,
                                    type: 'success',
                                    duration: 1000,
                                    onClose: function () {
                                        _this.$router.go(-1); // 返回上层路由
                                    }
                                });
                            } else {
                                _this.loading = false;
                                //修改失败
                                _this.$message({
                                    message: result.resp_msg,
                                    dangerouslyUseHTMLString: true,
                                    type: 'warning'
                                });
                            }
                        }
                    });
                }
                console.log(this.designFileId)
                console.log(file, fileList);
            },
            handlePreview(file) {
                console.log(file);
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${ file.name }？`);
            },
            back() {
                //返回按钮
            this.$router.push('/deveUnit');
            },
            handleSizeChange(val) {
                console.log('每页${val}条');
                this.pagesize = val;
            },
            handleCurrentChange(val) {
                console.log('当前页' + val);
                this.currpage = val;
                this.common(val);
            }, upUnit(row) {
                //修改按钮
                this.$router.push({name: 'upHouse'});
                localStorage.setItem("houseData", JSON.stringify(row));
            },
            uploadfile(row){
                //数据导入...
                this.dialogVisible = true;
                this.rowNumber=row;

            },
            downloadfile(row){
                if (row.designFileId==null){
                    this.$message.warning('未上传户室设计图，请先上传户室设计图！');
                }else {
                    location.href = this.url + '/api-file/files/downFileByfileId?fileid=' + row.designFileId + '&access_token=' + localStorage.getItem("token");
                }
            },
            deleteRow(index, rows, tableDate) {
                let _this = this;
                let _rows = rows;
                let _index = index;
                //删除相应单元
                this.$confirm('此操作将永久删除该户室信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(function () {
                    _this.loading = true;
                    $.ajax({
                        url: _this.url + "/api-public/opehouse/delete/" + _rows.houseId + "?access_token=" + localStorage.getItem("token"),
                        type: 'delete',
                        success: function (data) {
                            if (data.resp_code == 0) {
                                var that = _this;
                                that.loading = false;
                                tableDate.splice(index, 1);
                                //成功
                                that.$message({
                                    type: 'success',
                                    message: '删除成功!'
                                });
                            }
                        }
                    });
                }).catch(function () {
                });
            }, add() {
                //新增按钮
                this.$router.push({name: 'addHouse'});
            }, formatterHouseType(row) {
                //格式化户室类型
                if (row.houseType == 0) {
                    return "商品房";
                }
                if (row.houseType == 1) {
                    return "公租房";
                }
            }, bindHouseOwner(row) {
                //绑定业主
                this.ruleForm.houseId = row.houseId;
                this.dialogVisibleBindOwner = true;
            }, submitForm(formName) {
                const _this = this;
                this.$refs[formName].validate((valid) => {
                    if (valid) {

                        this.$confirm('确定要绑定该用户信息, 是否继续?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            //请求绑定业主的方法
                            $.ajax({
                                url: _this.url + "/api-public/buildownerHouse?access_token=" + localStorage.getItem("token"),
                                data: JSON.stringify(_this.ruleForm),
                                contentType: 'application/json',
                                type: 'post',
                                success: function (result) {
                                    if (result.resp_code == 0) {
                                        //关闭绑定弹框
                                        _this.dialogVisibleBindOwner = false;
                                        _this.$refs['ruleForm'].resetFields();
                                        _this.common();
                                        _this.$message({
                                            type: "success",
                                            message: result.resp_msg
                                        });
                                    }
                                    if (result.resp_code == 1) {
                                        // _this.$message({
                                        //     type:"error",
                                        //     message:result.resp_msg,
                                        //     dangerouslyUseHTMLString:true
                                        // });

                                        const h = _this.$createElement;

                                        _this.$msgbox({
                                            title: '',
                                            message: h('p', null, [
                                                h('i', {style: 'color: red;margin: 0 auto;'}, result.resp_msg)
                                            ]),
                                            dangerouslyUseHTMLString: true
                                        })


                                    }
                                }
                            });
                        }).catch(() => {
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }, formatterHouseState(row) {
                //格式化房屋状态
                if (row.state == 0) {
                    return "未绑定";
                } else if (row.state == 1) {
                    return "已绑定";
                } else if (row.state == 2) {
                    return "已激活";
                }
            },// 右上角 × 取消按钮
            closeDiv() {
                this.$nextTick(() => {
                    // form对应你写的<el-form ref="form"></el-form>
                    this.dialogVisibleBindOwner = false;
                    this.$refs["ruleForm"].resetFields();
                });
            }
        }
    }
</script>

<style scoped>
    .btn {
        float: right;
        transform: translateY(-34px);
    }
</style>